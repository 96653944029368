<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <!-- <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link> &gt;
                    <router-link :to="{ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">{{ this.$route.params.domain }}</router-link> &gt; -->
                    <router-link :to="{ name: 'account-view-domain-website-list', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">Websites</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ website.label }}</h1>
                <p class="text-caption text-center">Website overview</p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <template v-if="website">
                    <p class="text-overline mb-0 mt-8">URL</p>
                    <p class="mb-0 pb-0">
                        <TextLink :href="website.url" target="_blank">{{ website.url }}</TextLink>
                    </p>
                    </template>
                    <!-- show website aliases -->
                    <template v-if="Array.isArray(websiteAliasList)">
                    <p class="text-overline mb-0 mt-8">
                        Alternate URLs
                        <v-btn icon color="purple" :to="{ name: 'account-create-website-alias', params: { accountId: this.$route.params.accountId }, query: { websiteId: this.$route.params.websiteId, domain: website.domain } }">
                            <font-awesome-icon :icon="['fas', 'plus']"/>
                        </v-btn>
                    </p>

                    <p class="mb-0 pb-0" v-if="websiteAliasList.length === 0">
                        No alternate URLs.
                        <!-- <router-link :to="{ name: 'account-create-website-alias', params: { accountId: this.$route.params.accountId, websiteId: this.$route.params.websiteId } }">Create a website alias</router-link> -->
                    </p>
                    <p class="mb-0 pb-0" v-for="item in websiteAliasList" v-bind:key="item.id">
                        <TextLink :href="item.url" target="_blank">{{ item.url }}</TextLink>
                        <v-btn icon color="red darken-2" @click="confirmDeleteWebsiteAlias(item)">
                            <font-awesome-icon :icon="['fas', 'trash']"/>
                        </v-btn>
                    </p>
                    </template>
                    <v-dialog v-model="deleteWebsiteAliasDialog" max-width="600">
                        <v-card tile elevation="4" class="pa-0" max-width="600">
                            <v-toolbar short flat color="white">
                                <v-toolbar-title class="purple--text">Delete website alias</v-toolbar-title>
                            </v-toolbar>
                            <!-- <v-divider class="mx-5 mb-10"></v-divider> -->
                            <v-card-text v-if="websiteAlias">
                                <p>This action is not reversible.</p>
                                <p>Alias: {{ websiteAlias.url }}</p>
                            </v-card-text>

                            <!-- <v-divider></v-divider> -->

                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red" class="white--text" @click="deleteWebsiteAlias" :disabled="!websiteAlias">Delete</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <!-- TODO: should we allow changing domains? changing path? -->
                    <!-- TODO: allow changing the "zone" where it can be hosted (doesn't affect who can access it) or reset to default -->

                    <!-- <p class="mb-0 pb-0 mt-8">
                        <v-btn @click="editWebsite">Test</v-btn>
                    </p> -->

                    <template v-if="website">
                        <p class="text-overline mb-0 mt-8">Volume</p>
                        <template v-if="!volume">
                            <p class="mb-0 pb-0">
                                No volume selected.
                                <v-btn icon color="purple" @click="createWebsiteVolume">
                                    <font-awesome-icon :icon="['fas', 'plus']"/>
                                </v-btn>
                            </p>
                        </template>
                        <template v-if="volume">
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-edit-volume', params: { accountId: this.$route.params.accountId, volumeId: volume.id } }">{{ volume.label }}</router-link>
                            </p>
                            <p class="mb-0 pb-0">
                                <v-btn color="purple" class="white--text" @click="deployWebsiteContent">Deploy</v-btn>
                            </p>
                        </template>
                    </template>

                    <p class="text-overline mb-0 mt-8">Other</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-check-website-certificate', params: { accountId: this.$route.params.accountId, websiteId: this.$route.params.websiteId } }">Check certificate</router-link>
                    </p>

                    <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="purple--text"/></p>
                    <p class="mb-0 pb-0">
                        <v-btn color="purple" class="white--text" @click="deployConfig">Deploy configuration</v-btn>
                    </p>
                    </template>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        TextLink,
    },
    data: () => ({
        account: null,
        website: null,
        websiteAliasList: null,
        volume: null,
        error: null,
        websiteAlias: null,
        deleteWebsiteAliasDialog: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.website !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadWebsite() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadWebsite: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.searchWebsite({ domain: this.$route.params.domain, id: this.$route.params.websiteId });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list) && response.list.length === 1) {
                    this.website = response.list[0];
                    if (this.website.volume_id) {
                        this.loadVolume();
                    } else {
                        this.volume = null;
                    }
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load website');
                }
            } catch (err) {
                console.error('failed to load website', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadWebsite: false });
            }
        },
        async loadWebsiteAliasList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadWebsiteAliasList: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.searchWebsiteAlias({ website_id: this.$route.params.websiteId });
                console.log(`loadWebsiteAliasList: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.websiteAliasList = response.list;
                }
            } catch (err) {
                console.error('failed to load website alias list', err);
                // this.error = true;
            } finally {
                this.$store.commit('loading', { loadWebsiteAliasList: false });
            }
        },
        async loadVolume() {
            try {
                this.$store.commit('loading', { loadVolume: true });
                const response = await this.$client.account(this.$route.params.accountId).volume.get(this.website.volume_id);
                console.log(`loadVolume: response ${JSON.stringify(response)}`);
                if (response) {
                    this.volume = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load volume');
                }
            } catch (err) {
                console.error('failed to load volume', err);
            } finally {
                this.$store.commit('loading', { loadVolume: false });
            }
        },
        async editWebsite() {
            try {
                this.error = false;
                this.$store.commit('loading', { editWebsite: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.editWebsite({ id: this.$route.params.websiteId }, { test: 'foo' });
                console.log(`editWebsite: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'OK' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit website' });
                }
            } catch (err) {
                console.error('failed to edit website', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit website' });
            } finally {
                this.$store.commit('loading', { editWebsite: false });
            }
        },
        confirmDeleteWebsiteAlias(item) {
            this.websiteAlias = item;
            this.deleteWebsiteAliasDialog = true;
        },
        async deleteWebsiteAlias() {
            try {
                console.log(`deleteWebsiteAlias: item ${JSON.stringify(this.websiteAlias)}`);
                this.error = false;
                this.$store.commit('loading', { deleteWebsiteAlias: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.deleteWebsiteAlias({ website_id: this.websiteAlias.website_id, hostname: this.websiteAlias.hostname, domain: this.websiteAlias.domain });
                console.log(`deleteWebsiteAlias: response ${JSON.stringify(response)}`);
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Deleted website alias' });
                    this.websiteAlias = null;
                    this.loadWebsiteAliasList(); // TODO: find and delete it from cached list in memory, instead of reloading entire list from server
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete website alias' });
                }
            } catch (err) {
                console.error('failed to delete website alias', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete website alias' });
            } finally {
                this.$store.commit('loading', { deleteWebsiteAlias: false });
                this.deleteWebsiteAliasDialog = false;
            }
        },
        async createWebsiteVolume() {
            this.$router.push({ name: 'account-create-volume', params: { accountId: this.$route.params.accountId }, query: { website_id: this.$route.params.websiteId } });
            // try {
            //     console.log(`createWebsiteVolume for website ${this.website?.id}`);
            //     this.$store.commit('loading', { createWebsiteVolume: true });
            //     const response = await this.$client.account(this.$route.params.accountId).volume.create({ website_id: this.$route.params.websiteId });
            //     console.log(`createWebsiteVolume: response ${JSON.stringify(response)}`);
            //     if (response?.isCreated) {
            //         this.$bus.$emit('snackbar', { type: 'success', headline: 'Craeted volume for website' });
            //         if (response.volume_id) {
            //             this.$set(this.website, 'volume_id', response.volume_id);
            //         } else {
            //             this.loadWebsite();
            //         }
            //     } else {
            //         this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create volume for website' });
            //     }
            // } catch (err) {
            //     console.error('failed to create volume for website', err);
            //     this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create volume for website' });
            // } finally {
            //     this.$store.commit('loading', { createWebsiteVolume: false });
            // }
        },
        async deployConfig() {
            try {
                this.$store.commit('loading', { deployConfig: true });
                const response = await this.$client.account(this.$route.params.accountId).website.deployConf({ website_id: this.$route.params.websiteId });
                if (response?.status) {
                    console.log(`deployConfig: status ${response.status}`);
                }
                this.$bus.$emit('snackbar', { type: 'success', headline: 'Deployed configuration file' });
            } catch (err) {
                console.error('deployConfig failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to deploy configuration file' });
            } finally {
                this.$store.commit('loading', { deployConfig: false });
            }
        },
        async deployWebsiteContent() {
            try {
                this.$store.commit('loading', { deployWebsiteContent: true });
                const response = await this.$client.account(this.$route.params.accountId).website.deployFromVolume({ website_id: this.$route.params.websiteId });
                if (response?.status) {
                    console.log(`deployWebsiteContent: status ${response.status}`);
                }
                this.$bus.$emit('snackbar', { type: 'success', headline: 'Deployed website content' });
            } catch (err) {
                console.error('deployWebsiteContent failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to deploy website content' });
            } finally {
                this.$store.commit('loading', { deployWebsiteContent: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadWebsite();
        this.loadWebsiteAliasList();
    },
};
</script>
